import React from 'react';
import {IControllerProps} from '../../../types/app.types';
import {WidgetProps} from '@wix/yoshi-flow-editor';

const Context = React.createContext({} as IControllerProps);

export function useControllerProps(): IControllerProps {
  return React.useContext(Context);
}

export function ControllerContext({
  value,
  children,
}: {
  value: WidgetProps<IControllerProps>;
  children: React.ReactNode;
}) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
