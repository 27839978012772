import React from 'react';
import s from './ThankYouPageApp.scss';
import {useControllerProps} from '../ControllerContext';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {THANK_YOU_PAGE_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {PageContent} from './PageContent';

export enum ThankYouPageAppDataHook {
  root = 'ThankYouPageAppDataHook.root',
  rootContent = 'ThankYouPageAppDataHook.rootContent',
  blank = 'ThankYouPageAppDataHook.blank',
}

function ThankYouPageApp() {
  const {ssrError, skipRender} = useControllerProps();
  /* istanbul ignore if */
  if (ssrError || skipRender) {
    return <div data-hook={ThankYouPageAppDataHook.blank} />;
  }

  const MainContent = () => {
    return (
      <div data-hook={ThankYouPageAppDataHook.rootContent} className={s.limitContent}>
        <PageContent />
      </div>
    );
  };

  return (
    <main data-hook={ThankYouPageAppDataHook.root} className={s.root}>
      <MainContent />
    </main>
  );
}

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(ThankYouPageApp, {
  dsn: THANK_YOU_PAGE_DSN,
  config: {environment: 'Native Component'},
});
