// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {getPlanFrequencyByDurationTranslationKey} from '../../../order.helper';
import {SubscriptionFrequency} from '@wix/wixstores-client-storefront-sdk/dist/es/src/graphql/queries-schema';

export const getSubscriptionDuration = ({
  t,
  subscriptionDuration,
  subscriptionFrequency,
}: {
  t: any;
  subscriptionDuration: number;
  subscriptionFrequency: SubscriptionFrequency;
}) => {
  if (!subscriptionDuration) {
    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW');
  }

  const frequencyUnit = t(getPlanFrequencyByDurationTranslationKey(subscriptionFrequency, subscriptionDuration));
  if (subscriptionDuration === 1) {
    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
      frequencyUnitSingular: frequencyUnit,
    });
  }

  return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
    numberOfFrequencyUnits: subscriptionDuration,
    frequencyUnitSingular: frequencyUnit,
  });
};
