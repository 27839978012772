import React, {createContext, useContext, FC, PropsWithChildren} from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {LocaleKeys} from '../../locale-keys/LocaleKeys';

const LocaleKeysContext = createContext<LocaleKeys>(undefined);

export const LocaleKeysProvider: FC<PropsWithChildren> = ({children}) => {
  const {t} = useTranslation();
  const localeKeys = new LocaleKeys(t);

  return <LocaleKeysContext.Provider value={localeKeys}>{children}</LocaleKeysContext.Provider>;
};

export const useLocaleKeys = () => useContext(LocaleKeysContext);
