import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';

export type ISettingsParams = {
  THANK_YOU_PAGE_TITLE: SettingsParamType.String;
  THANK_YOU_PAGE_SUBTITLE: SettingsParamType.String;
  THANK_YOU_PAGE_SUBSCRIPTION_TITLE: SettingsParamType.String;
  THANK_YOU_PAGE_SUBSCRIPTION_SUBTITLE: SettingsParamType.String;
  THANK_YOU_PAGE_ORDER_NUMBER_LABEL: SettingsParamType.String;
  THANK_YOU_PAGE_TOTAL_COST_LABEL: SettingsParamType.String;
  THANK_YOU_PAGE_SHIPPING_ADDRESS_LABEL: SettingsParamType.String;
  THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_CAPTION: SettingsParamType.String;
  THANK_YOU_PAGE_CONTINUE_SHOPPING_LINK_OBJECT: SettingsParamType.Object;
  THANK_YOU_PAGE_BILLING_ADDRESS_LABEL: SettingsParamType.String;
  THANK_YOU_PAGE_PAYMENT_METHOD_LABEL: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  THANK_YOU_PAGE_TITLE: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_SUBTITLE: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_SUBSCRIPTION_TITLE: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_SUBSCRIPTION_SUBTITLE: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_ORDER_NUMBER_LABEL: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_TOTAL_COST_LABEL: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_SHIPPING_ADDRESS_LABEL: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_CAPTION: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('thankYouPage.continueBrowsing.footerLink'),
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_CONTINUE_SHOPPING_LINK_OBJECT: {
    type: SettingsParamType.Object,
    getDefaultValue: () => null,
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_BILLING_ADDRESS_LABEL: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('thankYouPage.billingInfo.label'),
    inheritFromAppScope: true,
  },
  THANK_YOU_PAGE_PAYMENT_METHOD_LABEL: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('thankYouPage.paymentMethod.label'),
    inheritFromAppScope: true,
  },
});
