import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from '../ShippingSection/ShippingSectionText';
import {useLocaleKeys} from '../../../LocaleKeysProvider';

export enum ExtendedFieldsViewDataHook {
  text = 'ExtendedFieldsViewDataHook.text',
  root = 'ExtendedFieldsViewDataHook.root',
}

export function ExtendedFieldsView() {
  const {extendedFields} = useControllerProps().thankYouPageStore;
  const localeKeys = useLocaleKeys();

  const getDisplayBooleanValue = (val: boolean): string => {
    return val
      ? localeKeys.thankyouPage.extendedFields.boolean.true()
      : localeKeys.thankyouPage.extendedFields.boolean.false();
  };

  return (
    <div data-hook={ExtendedFieldsViewDataHook.root}>
      {extendedFields.map(({value, label}) => (
        <ShippingSectionText
          dataHook={ExtendedFieldsViewDataHook.text}
          key={label}
          text={`${label}: ${typeof value === 'boolean' ? getDisplayBooleanValue(value) : value}`}
        />
      ))}
    </div>
  );
}
