import {isAddressEmpty} from '../../../addressUtils';
import {BillingAddress, ShippingAddress, PickupAddress} from '../../../../../types/app.types';

const isBillingAddressEmpty = ({billingAddress}: {billingAddress: BillingAddress}) => {
  return isAddressEmpty({
    addressLine1: billingAddress.addressLine1,
    addressLine2: billingAddress.addressLine2,
    city: billingAddress.city,
    country: billingAddress.country,
    subdivision: billingAddress.subdivision,
    zipCode: billingAddress.zipCode,
  });
};

export const shouldShowShipping = ({
  isPickupSelected,
  pickupAddress,
  shippingAddress,
}: {
  isPickupSelected: boolean;
  pickupAddress: PickupAddress;
  shippingAddress: ShippingAddress;
}) => {
  return isPickupSelected
    ? !isAddressEmpty({
        addressLine1: pickupAddress.addressLine,
        city: pickupAddress.city,
        country: pickupAddress.country,
        zipCode: pickupAddress.zipCode,
      })
    : !isAddressEmpty({
        addressLine1: shippingAddress.addressLine,
        addressLine2: shippingAddress.addressLine2,
        city: shippingAddress.city,
        country: shippingAddress.country,
        subdivision: shippingAddress.subdivision,
        zipCode: shippingAddress.zipCode,
      });
};

export const shouldShowBilling = ({billingAddress, totalPrice}: {billingAddress: BillingAddress; totalPrice: number}) =>
  !isBillingAddressEmpty({billingAddress}) && totalPrice !== 0;
