import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings';

type IStylesParams = {
  thankYou_textFontStyle: StyleParamType.Font;
  thankYou_titleFontStyle: StyleParamType.Font;
  thankYou_borderColor: StyleParamType.Color;
  thankYou_dividersColor: StyleParamType.Color;
  thankYou_textColor: StyleParamType.Color;
  thankYou_titleColor: StyleParamType.Color;
  thankYou_background: StyleParamType.Color;
  thankYou_widget_background: StyleParamType.Color;
  thankYou_borderWidth: StyleParamType.Number;
  thankYou_linksTextFontStyle: StyleParamType.Font;
  thankYou_linksTextColor: StyleParamType.Color;
  thankYou_infoTextFontStyle: StyleParamType.Font;
  thankYou_infoTextColor: StyleParamType.Color;
  THANK_YOU_PAGE_ORDER_NUMBER_SELECTION: StyleParamType.Boolean;
  THANK_YOU_PAGE_IMAGE_SELECTION: StyleParamType.Boolean;
  THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_SELECTION: StyleParamType.Boolean;
};

export default createStylesParams<IStylesParams>({
  thankYou_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  thankYou_titleFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  thankYou_borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  thankYou_dividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  thankYou_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thankYou_titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thankYou_background: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  thankYou_widget_background: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  thankYou_borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: /* istanbul ignore next */ () => 1,
  },
  thankYou_linksTextFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  thankYou_linksTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thankYou_infoTextFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  thankYou_infoTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  THANK_YOU_PAGE_ORDER_NUMBER_SELECTION: {type: StyleParamType.Boolean, getDefaultValue: () => true},
  THANK_YOU_PAGE_IMAGE_SELECTION: {type: StyleParamType.Boolean, getDefaultValue: () => true},
  THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_SELECTION: {type: StyleParamType.Boolean, getDefaultValue: () => true},
});
