import React, {PropsWithChildren} from 'react';
import {BillingColumn} from './BillingColumn';
import {ShippingColumn} from './ShippingColumn';
import * as s from './ShippingSection.scss';
import {PickupColumn} from './PickupColumn';
import {shouldShowBilling, shouldShowShipping} from '../Layout/utils';
import {useControllerProps} from '../../ControllerContext';
import {PaymentMethodsColumn} from './PaymentMethodsColumn';

export enum ShippingSectionDataHook {
  root = 'ShippingSectionDataHook.root',
  BillingSection = 'ShippingSectionDataHook.billingSection',
}

export function ShippingSection() {
  const {
    billingAddress,
    address: shippingAddress,
    totalPrice,
    pickupAddress,
    isPickupSelected,
  } = useControllerProps().thankYouPageStore;

  const showBilling = shouldShowBilling({billingAddress, totalPrice});
  const showShipping = shouldShowShipping({isPickupSelected, pickupAddress, shippingAddress});
  return (
    <div className={s.section} data-hook={ShippingSectionDataHook.root}>
      {showShipping && <Column>{isPickupSelected ? <PickupColumn /> : <ShippingColumn />}</Column>}
      {showBilling && (
        <Column dataHook={ShippingSectionDataHook.BillingSection}>
          <BillingColumn />
        </Column>
      )}
      <Column>
        <PaymentMethodsColumn />
      </Column>
    </div>
  );
}

const Column: React.FC<PropsWithChildren<{dataHook?: string}>> = (props) => {
  return (
    <div className={s.column} data-hook={props.dataHook}>
      {props.children}
    </div>
  );
};
